import { UnlockOutlined } from "@ant-design/icons";
import { Result, Button, Avatar, Modal, Layout, Input, Form } from "antd";
import { useState } from "react";
import { invokeSnap } from "../utils";
import Highlight, { defaultProps } from "prism-react-renderer";

const exampleCode = `params: [
  {
    from: '0xb60e8dd61c5d32be8058bb8eb970870f07233155',
    to: '0xd46e8dd67c5d32be8058bb8eb970870f07244567',
    value: '0x9184e72a', // 2441406250
    data:
      '0xd46e8dd67c5d32be8d46e8dd67c5d32be8058bb8eb970870f072445675058bb8eb970870f072445675',
  },
];

mevNFT
  .request({
    method: 'eth_private_sendTransaction',
    params,
  })
  .then((result) => {
    // The result after the transaction is sent through Flashbots protect.
  })
  .catch((error) => {
    // If the request fails, the Promise will reject with an error.
  });`;

const { Content } = Layout;
const API_URL =
  "https://script.google.com/macros/s/AKfycbz1kzJGaJ5wqGLWlSoJCYK6tklamW6qN2LRTSOzm3IcqcWG-5UulcLPrYNgYHbs6uDL/exec";

const FormModal = (props) => {
  const { visible, setIsVisible, setSignedUp, isSignedUp } = props;
  const [form] = Form.useForm();
  const [postData, setPostData] = useState({
    loading: false,
    error: false,
    data: [],
  });

  const onSubmit = (values) => {
    setPostData({ ...postData, loading: true, error: false });
    // post request to API_URL
    fetch(API_URL, {
      method: "POST",
      body: JSON.stringify(values),
    }).then((response) => {
      setPostData({
        ...postData,
        loading: false,
        error: false,
      });
      setSignedUp(true);
      setIsVisible(false);
    });
  };

  return (
    <>
      <Modal
        visible={visible}
        title="Beta Access"
        onCancel={() => {
          setIsVisible(false);
        }}
        footer={
          isSignedUp
            ? null
            : [
                <Button
                  key="submit"
                  type="primary"
                  loading={postData.loading}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        onSubmit(values);
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                >
                  Submit
                </Button>,
              ]
        }
      >
        {isSignedUp ? (
          <div>
            <p>Thanks for signing up! We'll be in touch soon.</p>
          </div>
        ) : (
          <Form
            form={form}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                form.validateFields().then((values) => {
                  onSubmit(values);
                });
              }
            }}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <p>
              Sign up for beta access to MEV NFT. We will be in touch with you
              shortly.
            </p>
            <Form.Item
              label="E-Mail or Telegram"
              name="contact"
              rules={[
                {
                  required: true,
                  message: "",
                  min: 4,
                },
              ]}
            >
              <Input />
            </Form.Item>

            {postData.error && (
              <>
                <br />
                <span style={{ color: "red" }}>{postData.data}</span>
              </>
            )}
          </Form>
        )}
      </Modal>
    </>
  );
};

const ContentWrapper = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignedUp, setSignedUp] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div style={{ zIndex: 1 }}>
      <FormModal
        visible={isModalOpen}
        setIsVisible={setIsModalOpen}
        setSignedUp={setSignedUp}
        isSignedUp={isSignedUp}
      />
      <Content
        style={{ zIndex: 1, paddingBottom: "150px", overflowY: "scroll" }}
      >
        <Result
          icon={<UnlockOutlined color="success" />}
          title="Secure your transactions"
          subTitle="Connect MetaMask Flask and gain transactions insights through ML"
          extra={[
            <Button type="primary" key="install" onClick={showModal}>
              Install
            </Button>,
            <Button onClick={showModal} key="buy">
              Set up secure wallet
            </Button>,
          ]}
        />
        {/* <Result
          icon={
            <div
              style={{
                width: "80vw",
                // height: "69.8vh",
                margin: "auto",
                border: "1px solid #acacac",
                borderRadius: "6px",
                boxShadow: "0px 0px 20px #acacac",
                textAlign: "left",
              }}
            >
              <Highlight {...defaultProps} code={exampleCode} language="js">
                {({
                  className,
                  style,
                  tokens,
                  getLineProps,
                  getTokenProps,
                }) => (
                  <pre
                    className={className}
                    style={{
                      ...style,
                      padding: "10px 0px 0px 10px",
                      borderRadius: "6px",
                      marginBottom: 0,
                    }}
                  >
                    {tokens.map((line, i) => (
                      <div {...getLineProps({ line, key: i })}>
                        {line.map((token, key) => (
                          <span {...getTokenProps({ token, key })} />
                        ))}
                      </div>
                    ))}
                  </pre>
                )}
              </Highlight>
            </div>
          }
          title="Mint privately on Ethereum"
          subTitle="Don't waste gas on failed transactions"
          extra={[
            <Button onClick={showModal} key="docs">
              See docs
            </Button>,
          ]}
        /> */}
        <Result
          icon={
            <div
              style={{
                width: "80vw",
                // height: "69.8vh",
                margin: "auto",
                border: "1px solid #acacac",
                borderRadius: "6px",
                boxShadow: "0px 0px 20px #acacac",
                textAlign: "left",
              }}
            >
              <img style={{ width: "100%" }} src="/img/image8.png" />
            </div>
          }
          title="Full-suite for marketplaces to detect malicious activities"
          subTitle="Use the MEV NFT extension to protect yourself"
          extra={[
            <Button key="extension" onClick={showModal}>
              Install Chrome Extension
            </Button>,
          ]}
        />
        <Result
          icon={<div></div>}
          title="Stay tuned"
          subTitle="We'll be in touch soon"
          extra={[
            <Button type="primary" key="extension" onClick={showModal}>
              Sign up for the Beta
            </Button>,
            <Button
              type="primary"
              key="extension"
              href="https://twitter.com/mevtools"
              target="blank"
            >
              @MEVTOOLS on Twitter
            </Button>,
          ]}
        />
      </Content>
    </div>
  );
};

export default ContentWrapper;
