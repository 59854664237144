import { Row, Col, Button, Layout, Grid } from "antd";
import { ReactComponent as QuickNode } from "../assets/qn-logo.svg";
import { ReactComponent as CoinBase } from "../assets/cb-logo.svg";
import { ReactComponent as Sozu } from "../assets/sozuLogo.svg";
import { MetaMask } from "./MetaMask";
import { PoweredBy } from "./PoweredBy";

export const Footer = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <Layout.Footer
      style={{
        ...(!Object.entries(screens).find(
          (screen) => screen[0] === "md"
        )?.[1] && { display: "none" }),
        backgroundColor: "#141414",
        zIndex: 1,
        position: "sticky",
        left: 0,
        bottom: 0,
        width: "100%",
      }}
    >
      <Row justify="space-around" style={{ paddingBottom: "20px" }}>
        <Col>Technology powered by </Col>
      </Row>
      <Row justify="space-around">
        <Col>
          <Button size="large" style={{ width: "100%", height: "60px" }}>
            <MetaMask color="orange" />
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{ height: "60px", width: "100%" }}
            icon={<QuickNode style={{ height: "28px", paddingTop: "5px" }} />}
          ></Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{ height: "60px", width: "100%", minWidth: "180px" }}
            icon={<CoinBase style={{ height: "24px" }} />}
          ></Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{ height: "60px", width: "190px" }}
            icon={
              <Sozu
                style={{ height: "30px", width: "50px", marginTop: "8px" }}
              />
            }
          ></Button>
        </Col>
      </Row>
    </Layout.Footer>
  );
};
